import React from 'react';

import s from './s.module.scss';

interface CoreTechProps {
  icon: React.ReactNode;
  children: React.ReactNode;
}

const CoreTech: React.FC<CoreTechProps> = props => {
  const { icon, children } = props;

  return (
    <div className={s.root}>
      <div className={s.icon}>{icon}</div>
      <div className={s.text}>{children}</div>
    </div>
  );
};

export default CoreTech;
