import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { SiteConfig } from '../../types/site-metadata';
import Company from '../Company';

import s from './s.module.scss';

const Career: React.FC = () => {
  const {
    site: {
      siteMetadata: { career, education }
    }
  } = useStaticQuery<SiteConfig>(graphql`
    query CareerQuery {
      site {
        siteMetadata {
          career {
            companyName
            startDate
            endDate
            location
            position
            responsibilities
          }
          education {
            companyName
            startDate
            endDate
            location
            position
            responsibilities
          }
        }
      }
    }
  `);

  return (
    <div>
      <h2>Career</h2>

      <div className={s.companiesList}>
        {career.map(careerItem => (
          <div className={s.company} key={careerItem.companyName}>
            <Company {...careerItem} />
          </div>
        ))}
      </div>

      <h3>Education</h3>

      <div className={s.companiesList}>
        {education.map(eduItem => (
          <div className={s.company} key={eduItem.companyName}>
            <Company {...eduItem} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Career;
