import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { SiteConfig } from '../../types/site-metadata';

import CoreTech from '../CoreTech';

import s from './s.module.scss';

const Technologies: React.FC = () => {
  const {
    site: {
      siteMetadata: { technologiesList }
    }
  } = useStaticQuery<SiteConfig>(graphql`
    query TechnologiesList {
      site {
        siteMetadata {
          technologiesList
        }
      }
    }
  `);

  return (
    <div>
      <h2>Technologies</h2>

      <div className={s.coreTechRow}>
        <div className={s.coreTech}>
          <CoreTech icon={<i>ts</i>}>TypeScript</CoreTech>
        </div>

        <div className={s.coreTech}>
          <CoreTech icon={<i>nodejs</i>}>Node.js</CoreTech>
        </div>

        <div className={s.coreTech}>
          <CoreTech icon={<i>react</i>}>React</CoreTech>
        </div>
      </div>

      <div className={s.techGrid}>
        {technologiesList.map((t: string) => (
          <div className={s.tech} key={t}>
            {t}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Technologies;
