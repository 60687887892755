import React from 'react';

import s from './s.module.scss';

export interface CompanyProps {
  companyName: string;
  location: string;
  responsibilities: string[];
  startDate: string;
  endDate: string;
  position: string;
}

const Company: React.FC<CompanyProps> = props => {
  const {
    companyName,
    location,
    startDate,
    endDate,
    responsibilities,
    position
  } = props;

  return (
    <div className={s.root}>
      <div className={s.leftGroup}>
        <h4 className={s.companyName}>{companyName}</h4>

        <h5 className={s.period}>
          {startDate}
          {' - '}
          {endDate}
        </h5>

        <h5 className={s.location}>{location}</h5>
      </div>

      <div className={s.rightGroup}>
        <h4 className={s.position}>{position}</h4>
        <ul className={s.responsibilitiesList}>
          {responsibilities.map(r => (
            <li key={r}>{r}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Company;
