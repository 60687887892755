import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { SiteConfig } from '../../types/site-metadata';

import s from './s.module.scss';

const AboutMe: React.FC = () => {
  const {
    site: {
      siteMetadata: { aboutMe }
    }
  } = useStaticQuery<SiteConfig>(graphql`
    query AboutMe {
      site {
        siteMetadata {
          aboutMe
        }
      }
    }
  `);

  return (
    <div>
      <h2>About me</h2>

      <p className={s.text}>{aboutMe}</p>
    </div>
  );
};

export default AboutMe;
