import React from 'react';

import AboutMe from '../components/AboutMe';
import Career from '../components/Career';
import ContentMainColumn from '../components/ContentMainColumn';
import LineSeparator from '../components/LinesSeparator';
import PageLayout from '../components/PageLayout';
import Technologies from '../components/Technologies';
import WhiteRow from '../components/WhiteRow';

const IndexPage: React.FC = () => {
  return (
    <PageLayout
      seo={{
        title: 'Tit Kovalenko'
      }}
    >
      {() => (
        <WhiteRow>
          <ContentMainColumn>
            <LineSeparator />

            <AboutMe />

            <LineSeparator />

            <Technologies />

            <LineSeparator />

            <Career />
          </ContentMainColumn>
        </WhiteRow>
      )}
    </PageLayout>
  );
};

export default IndexPage;
